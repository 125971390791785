var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "fb-page",
    [
      _c(
        "fb-header",
        {
          attrs: { slot: "header", title: _vm.title, fixed: "" },
          slot: "header",
        },
        [
          _c(
            "fb-button",
            {
              attrs: {
                slot: "left",
                icon: "back",
                size: "small",
                type: "primary",
              },
              on: {
                click: function ($event) {
                  return _vm.backList(0)
                },
              },
              slot: "left",
            },
            [_vm._v(" 返回 ")]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "oa-loan" },
        [
          _c("div", { staticClass: "loan-list" }, [
            _c("div", { staticClass: "loan" }, [
              _c("div", { staticClass: "loan-top" }, [
                _c("span", [_vm._v("借款")]),
                _c("i"),
              ]),
              _c("div", { staticClass: "loan-center" }, [
                _c(
                  "div",
                  { staticClass: "loan-money" },
                  [
                    _c("van-field", {
                      attrs: {
                        label: "金额（元）",
                        placeholder: "请输入金额",
                        "input-align": "right",
                        readonly: "",
                        clickable: "",
                      },
                      on: {
                        touchstart: function ($event) {
                          $event.stopPropagation()
                          _vm.show = true
                        },
                      },
                      model: {
                        value: _vm.loan.amountOfMoney,
                        callback: function ($$v) {
                          _vm.$set(_vm.loan, "amountOfMoney", $$v)
                        },
                        expression: "loan.amountOfMoney",
                      },
                    }),
                    _c("van-number-keyboard", {
                      attrs: {
                        show: _vm.show,
                        theme: "custom",
                        "extra-key": ".",
                        "close-button-text": "完成",
                      },
                      on: {
                        blur: function ($event) {
                          _vm.show = false
                        },
                        input: _vm.onInput,
                        delete: _vm.onDelete,
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  [
                    _c("van-field", {
                      attrs: {
                        label: "类别",
                        placeholder: "如：打车费、交通费",
                        "input-align": "right",
                      },
                      model: {
                        value: _vm.loan.type,
                        callback: function ($$v) {
                          _vm.$set(_vm.loan, "type", $$v)
                        },
                        expression: "loan.type",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "loan-center-textarea" },
                  [
                    _c("van-field", {
                      attrs: {
                        rows: "3",
                        autosize: "",
                        label: "事由",
                        type: "textarea",
                        placeholder: "请输入费用明细描述",
                      },
                      model: {
                        value: _vm.loan.cause,
                        callback: function ($$v) {
                          _vm.$set(_vm.loan, "cause", $$v)
                        },
                        expression: "loan.cause",
                      },
                    }),
                    _c("i", {
                      staticClass: "iconfont icon-link",
                      on: { click: _vm.addImg },
                    }),
                  ],
                  1
                ),
                _vm.loan.collections.length > 0
                  ? _c("div", { staticClass: "oa-receive-content-box" }, [
                      _c(
                        "div",
                        { staticClass: "oa-receive-content-box-resource" },
                        _vm._l(_vm.loan.collections, function (items, index) {
                          return _c(
                            "div",
                            { key: index, staticClass: "resource-image-box" },
                            [
                              items.type === "WORD"
                                ? _c("i", {
                                    staticClass: "iconfont icon-word",
                                    staticStyle: {
                                      color: "rgb(108, 168, 255)",
                                    },
                                  })
                                : _vm._e(),
                              items.type === "EXCEL"
                                ? _c("i", {
                                    staticClass: "iconfont icon-exl",
                                    staticStyle: {
                                      color: "rgb(139, 221, 108)",
                                    },
                                  })
                                : _vm._e(),
                              items.type === "PDF"
                                ? _c("i", {
                                    staticClass: "iconfont icon-PDF",
                                    staticStyle: { color: "rgb(62, 179, 240)" },
                                  })
                                : _vm._e(),
                              items.type === "VIDEO"
                                ? _c("i", {
                                    staticClass: "iconfont icon-shipin",
                                    staticStyle: { color: "rgb(75, 158, 251)" },
                                  })
                                : _vm._e(),
                              items.type === "AUDIO"
                                ? _c("i", {
                                    staticClass: "iconfont icon-shipin",
                                    staticStyle: { color: "rgb(75, 158, 251)" },
                                  })
                                : _vm._e(),
                              items.type === "IMAGE"
                                ? _c("img", {
                                    staticClass: "resource-image-img",
                                    attrs: { src: items.content, alt: "" },
                                  })
                                : _vm._e(),
                              _c("img", {
                                staticClass: "resource-image-delete",
                                attrs: {
                                  src: require("../../../../../public/img/icons/icon_delete.png"),
                                  alt: "",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.deleteImg(index)
                                  },
                                },
                              }),
                            ]
                          )
                        }),
                        0
                      ),
                    ])
                  : _vm._e(),
              ]),
            ]),
          ]),
          _vm.loan.progress === "UNAUDITED" ||
          _vm.loan.progress === "APPROVED" ||
          _vm.loan.progress === "CERTIFIED_FAILED"
            ? _c(
                "div",
                { staticClass: "oa-approval" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "oa-approval-title",
                      staticStyle: { "margin-bottom": "20px" },
                    },
                    [_vm._v("审批记录")]
                  ),
                  _c("approval-record", {
                    attrs: { typeId: this.id, type: "BORROW_RECOED_ADD" },
                  }),
                ],
                1
              )
            : _vm.approvalUserComp
            ? _c("approval-user", {
                attrs: {
                  Approval: this.Approval,
                  CC: this.CC,
                  isAdd: _vm.isAdd,
                  approvalUserSource: _vm.approvalUser,
                  ccUserSource: _vm.ccUser,
                },
                on: { userSelect: _vm.userSelect, coopSelect: _vm.coopSelect },
              })
            : _vm._e(),
          _vm.operateBtnShow
            ? _c("operate-btn", {
                attrs: {
                  isAdd: _vm.isAdd,
                  progress: _vm.loan.progress,
                  approveRecordUsers: _vm.loan.approveRecordUsers,
                  Approval: this.Approval,
                  CC: this.CC,
                },
                on: { save: _vm.save },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }